.effect-section {
    position: relative;
    overflow: hidden;
    .shap-top-left {
        position: absolute;
        width: 100vh;
        height: 100vh;
        left: -30vh;
        top: -30vh;
        pointer-events: none;
    }
    .shap-bottom-right {
        position: absolute;
        width: 100vh;
        height: 100vh;
        right: -30vh;
        bottom: -30vh;
        pointer-events: none;
    }
    .shap-middle-left {
        position: absolute;
        top: 0;
        left: -25vh;
        bottom: 0;
        margin: auto;
        width: 40%;
        height: 100vh;
        max-width: 100vw;
        pointer-events: none;
        display: flex;
        align-items: center;
    }
    .shap-middle-left-01 {
        position: absolute;
        top: -25%;
        left: -25%;
        bottom: -25%;
        margin: auto;
        right: 70%;
    }
    .shap-middle-right {
        position: absolute;
        top: 0;
        right: -25vh;
        bottom: 0;
        margin: auto;
        width: 100vh;
        height: 100vh;
        display: flex;
        align-items: center;
    }
}

/* particles-box
---------------------------*/
.particles-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.2;
    pointer-events: none;
}


/* Section Effect
------------------------*/
.effect-bottom {
    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
}


/* Scale Effect
------------------------*/
.scale-ani {
    animation: scale-animation 5s linear infinite;
}

@keyframes scale-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}


@include media-breakpoint-up(lg) {
    .sticky-lg-top-header {
        top: 100px;
    }
}


.effect-skew {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50%;
    right: 31%;
    transform: skew(-35deg);
    border-radius: 0 0 180px 0;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
        right: -40%;
        left: -90%;
    }
}

.effect-radius {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0% 100% 20% 80% / 73% 0% 100% 27%;

    @include media-breakpoint-down(sm) {
        bottom: 30px;
        left: -50px;
        right: -40px;
    }
}

.effect-shape {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    &:after {
        content: "";
        position: absolute;
        bottom: -40%;
        background: linear-gradient(to left, rgba(var(--#{$prefix}white-rgb), 0.08), rgba(var(--#{$prefix}black-rgb), 0.05));
        right: -25%;
        margin: auto;
        transform: skew(30deg);
        top: -40%;
        left: 45%;
        border-radius: 50%;
    }
}

.effect-radius-bg {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 23rem;
        height: 23rem;
        border-radius: 100%;
        background-image: linear-gradient(-180deg, rgba(227, 221, 246, 0.1) 0%, rgba(227, 221, 246, 0.2) 65%, rgba(227, 221, 246, 0.1) 100%);
        z-index: -1;
    }

    .radius-1 {
        transform: translate(-50%, -50%) scale(0.67);
    }

    .radius-2 {
        transform: translate(-50%, -50%) scale(1.05);
    }

    .radius-3 {
        transform: translate(-50%, -50%) scale(1.63);
    }

    .radius-4 {
        transform: translate(-50%, -50%) scale(2.1);
    }

    .radius-x {
        will-change: transform;
        animation: pulsate 3s infinite;
    }
}

@keyframes pulsate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.5);
        transform: translate(-50%, -50%) scale(0.5)
    }

    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(4);
        transform: translate(-50%, -50%) scale(4)
    }

}



.after-k:after {
  content: "K";
}

.after-p:after {
  content: "%";
}

.after-plus:after {
  content: "+";
}


.after-shadow {
    z-index: 1;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
        pointer-events: none;
        background: currentColor;
        z-index: -1;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

// Icon Dots Effect
.dots-icon {
    position: relative;
}

.dots {
    z-index: 0;
    animation: dots 6s infinite linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .dot {
        width: 5px;
        height: 5px;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -3px;
        margin-top: -3px;
    }

    .dot1 {
        background-color: #8ad9fd;
        transform: translate(15px, 5px);
        animation: dot1 1.5s infinite alternate;
    }

    .dot2 {
        background-color: #b8a6f7;
        transform: translate(-8px, 8px);
        animation: dot2 1.5s infinite alternate;
        animation-delay: .5s;
    }

    .dot3 {
        background-color: #fac5a7;
        transform: translate(-5px, -12px);
        animation: dot3 1.5s infinite alternate;
        animation-delay: 1s;
    }
}

@keyframes dots {
    from {
        transform: rotate(-360deg)
    }

    to {
        transform: rotate(0)
    }

}

@keyframes dot1 {
    from {
        transform: translate(15px, 5px);
        opacity: 1;
    }

    to {
        transform: translate(25px, 5px);
        opacity: 1;
    }

}

@keyframes dot2 {
    from {
        transform: translate(-8px, 8px);
        opacity: 1;
    }

    to {
        transform: translate(-19px, 19px);
        opacity: 1;
    }

}

@keyframes dot3 {
    from {
        transform: translate(-5px, -12px);
        opacity: 1;
    }

    to {
        transform: translate(-10px, -24px);
        opacity: 1;
    }

}


// Hover rotate effect
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@mixin hover-rotate {
    animation: lds-dual-ring 1.2s linear infinite;
    animation-play-state: paused;
}

@mixin hover-rotate-hover {
    animation-play-state: running;
}

.hover-rotate {
    .hr-rotate {
        @include hover-rotate;
    }

    .hr-rotate-after {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            margin: auto;
            border-radius: 50px;
            transition: ease all 0.55s;
            z-index: -1;
            @include hover-rotate;
        }
    }

    .ef-1 {
        &:after {
            border-top: 50px solid transparent;
            border-left: 50px solid currentColor;
            top: -10px;
            left: -10px;
            border-right: 50px solid transparent;
            border-bottom: 50px solid transparent;
        }
    }

    .ef-2 {
        &:after {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            border: 4px solid currentcolor;
            border-bottom: 4px solid transparent;
        }
    }

    .ef-3 {
        &:after {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
            border: 2px solid currentcolor;
            border-bottom: 2px solid transparent;
            border-top: 2px solid transparent;
        }
    }

    .ef-4 {
        &:after {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
            border: 2px solid currentcolor;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
            border-top: 2px solid transparent;
        }
    }

    &:hover {
        .hr-rotate {
            @include hover-rotate-hover;
        }

        .hr-rotate-after {
            &:after {
                @include hover-rotate-hover;
            }
        }
    }
}

.arrow-icon {
    width: 32px;
    height: 12px;
    position: relative;

    &:before {
        width: 32px;
        height: 2px;
        background: currentColor;
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 12px;
        height: 12px;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        margin: auto;
        right: 0;
        transform: rotate(-45deg);
    }
}

.count-down-01 {
    display: flex;
    flex-wrap: wrap;
    > div {
        text-align: center;
        background: var(--#{$prefix}white);
        line-height: 1;
        padding: 10px 0;
        margin: 3px 7px 3px 0px;
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius;
    }
    .count {
        font-size: 20px;
        color: var(--#{$prefix}dark);
        display: inline-block;
        vertical-align: top;
        font-weight: 700;
        width: 100%;
    }
    .title {
        font-size: 14px;
    }
}


