// Nav pills primary soft
.nav-pills-primary-soft {
    .nav-link {
        padding: 0.5rem 0.8rem !important;
        color: var(--#{$prefix}gray-800);
        font-weight: 500;
        border-radius: $border-radius;

        &.active,
        &:hover {
            color: var(--#{$prefix}primary);
            background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
        }
    }
    &.flex-column {
        > .nav-item {
            + .nav-item {
                margin-top: 0.5rem;
            }
        }
    }
}


.nav-sub {
	.nav-icon {
		width: 1.25rem;
	    font-size: .8125rem;
	    line-height: 1.4;
	    opacity: .8;
	    text-align: center;
	    margin-right: .3rem;
	}
	.nav-item {
		width: 100%;
	}
	.nav-link {
		color: var(--#{$prefix}body-color);
		padding: 8px 0;
		&:hover {
			color: var(--#{$prefix}primary);
		}
		&.active {
			color: var(--#{$prefix}primary);
		}
	}
}