.breadcrumb-item {
    a {
        color: $body-color;

        &:hover {
            color: var(--#{$prefix}primary);
        }
    }
}


.breadcrumb.breadcrumb-dark {
    .breadcrumb-item {
        a {
            color:var(--#{$prefix}gray-300);

            &:hover {
                color: var(--#{$prefix}primary);
            }
        }

        &.active {
            color: var(--#{$prefix}gray-400);
        }
    }

}

.breadcrumb.breadcrumb-light {
    --#{$prefix}breadcrumb-divider-color: rgba(var(--#{$prefix}white-rgb), 0.75);
    .breadcrumb-item {
        a {
            color: rgba(var(--#{$prefix}white-rgb), 1);

            &:hover {
                color: rgba(var(--#{$prefix}white-rgb), 0.75);
            }
        }

        &.active {
            color: rgba(var(--#{$prefix}white-rgb), 0.75);
        }
    }

}

.breadcrumb.breadcrumb-dots {
    .breadcrumb-item {
        +.breadcrumb-item {
            &::before {
                content: "•";
                font-size: 22px;
                line-height: 0;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}