.header-classic {
    @include media-breakpoint-down(lg) {
        .navbar-collapse {
            position: absolute;
            top: 100%;
            left: 0px;
            width: 100%;
            background: var(--#{$prefix}body-bg);
            padding: 8px 15px;
            max-height: 80vh;
            overflow-y: auto;
            z-index: 11;
            .navbar-dark & {
                background: var(--#{$prefix}dark);
            }
        }
    }
}