.text-link-hover {
    &:hover {
        --#{$prefix}text-opacity: 1 !important;
    }
}

.link-effect {
    position: relative;
    padding: 0;
    border: none;
    background: none;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: auto;
        width: 100%;
        height: 1px;
        background: currentColor;
        transition: ease all 0.35s;
    }

    &:hover {
        &:after {
            width: 0;
            right: 0;
            left: auto;
        }
    }
}

.link-effect-02 {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: auto;
        right: 0;
        width: 0px;
        height: 1px;
        transition: ease all 0.35s;
        background: currentColor;
    }

    &:hover {
        &:after {
            left: 0;
            right: auto;
            width: 100%;
        }
    }
}

.link-effect-03 {
    background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
    background-size: 0px 1px;
    background-position: 0px calc(100% - 1px);
    background-repeat: no-repeat;
    transition: ease all 0.35s;
    &.active,
    &:hover {
        background-size: 100% 1px;
    }
    &-2x {
        background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
        background-size: 0px 2px;
        background-position: 0px calc(100% - 2px);
        background-repeat: no-repeat;
        transition: ease all 0.35s;
        &.active,
        &:hover {
            background-size: 100% 2px;
        }
    }
}