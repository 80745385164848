.header-seo {
    --header-s-icon-w: 40px;
    --header-s-icon-h: 40px;
    
    .header-desk {
        opacity: 0;
        transition: ease all 0.3s;
    }
    &.headroom--unpinned {
        top: -120px;
    }
    &.headroom--pinned.headroom--not-top {
        background-color: var(--#{$prefix}body-bg);
        .header-desk {
            opacity: 1;
        }
    }
    .h-theme-switch {
        .dropdown-toggle {
            width: var(--header-s-icon-w);
            height: var(--header-s-icon-h);
            background-color: rgba(var(--#{$prefix}bg-mode), var(--#{$prefix}bg-opacity, 1));
            color: rgba(var(--#{$prefix}text-mode-re), var(--#{$prefix}text-opacity, 1));
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0;
            font-size: 22px;
            border-radius: 50%;
            &:after {
                display: none;
            }
        }
    }
    .h-toggle-btn {
        .navbar-toggler {
            width: var(--header-s-icon-w);
            height: var(--header-s-icon-h);
            background-color: rgba(var(--#{$prefix}bg-mode), var(--#{$prefix}bg-opacity, 1));
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0;
            position: relative;
            border-radius: 50%;
            .menu-toggler-icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 22px;
                height: 2px;
                background: rgba(var(--#{$prefix}text-mode-re), var(--#{$prefix}text-opacity, 1));
                margin: auto;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: -7px;
                    left: 0;
                    right: 0;
                    width: 75%;
                    height: 2px;
                    background: rgba(var(--#{$prefix}text-mode-re), var(--#{$prefix}text-opacity, 1));
                }

                &:before {
                    top: auto;
                    bottom: -7px;
                }
            }
        }
    }

    .header-desk {
        .navbar-nav {
            flex-direction: row;
            gap: 1.5rem;
            .nav-link {
                --#{$prefix}nav-link-font-size: 14px;
                --#{$prefix}nav-link-font-weight: 600;
                --#{$prefix}nav-link-color: rgba(var(--#{$prefix}text-mode), var(--#{$prefix}text-opacity, 1));
                --#{$prefix}nav-link-hover-color: var(--#{$prefix}primary);
                --#{$prefix}navbar-active-color: var(--#{$prefix}primary);
                padding: 0;
            }
        }
    }

    .nav-offcanvas {
        --#{$prefix}offcanvas-width: 100%;
        transition: none;
        background: var(--#{$prefix}primary);
        .container {
            position: relative;
        }
        
        .btn-close-d {
            border-radius: 50%;
            background: var(--#{$prefix}white);
            width: 40px;
            height: 40px;
            .btn-close {
                width: 40px;
                height: 40px;
                opacity: 1;
                margin: 0;
                display: inline-block;
                vertical-align: top;
                padding: 0;
                filter: inherit;
            }
        }
        .navbar-nav {
            .nav-item {
                + .nav-item {
                    padding-top: 20px;
                    border: none;
                    @include media-breakpoint-down(lg) {
                        padding-top: 15px;
                    }
                }
            }
        }
        .nav-link {
            --#{$prefix}nav-link-font-size: 40px;
            --#{$prefix}nav-link-font-weight: 700;
            --#{$prefix}nav-link-color: var(--#{$prefix}white);
            --#{$prefix}nav-link-hover-color: var(--#{$prefix}white);
            text-transform: uppercase;
            line-height: 1.1;
            display: inline-flex !important;
            @include media-breakpoint-down(lg) {
                --#{$prefix}nav-link-font-size: 35px;
            }
            @include media-breakpoint-down(md) {
                --#{$prefix}nav-link-font-size: 25px;
            }
        }
    }
}