.browser-device {
    position: relative;
    z-index: 1;
    display: block;
    max-width: 100%;
    .browser-device-header {
	    position: relative;
	    display: flex;
	    background: var(--#{$prefix}white);
	    border-bottom: 1px solid var(--#{$prefix}gray-300);
	    border-top-left-radius: .5rem;
	    border-top-right-radius: .5rem;
	    padding: .5rem 6.5rem;
	    .browser-device-action {
    	    display: flex;
		    grid-gap: .25rem;
		    position: absolute;
		    top: calc(50% - .25rem);
		    left: 1rem;
		    span {
	    	    width: .5rem;
			    height: .5rem;
			    background-color: var(--#{$prefix}gray-300);
			    border-radius: 50%;
		    }
	    }
	    .browser-device-bar {
    	    display: flex;
		    justify-content: center;
		    align-items: center;
		    width: 100%;
		    height: 100%;
		    color: var(--#{$prefix}gray-800);
		    background-color: var(--#{$prefix}gray-100);
		    font-size: .5rem;
		    border-radius: .125rem;
	    }
    }
    .browser-device-frame {
	    border-bottom-right-radius: .5rem;
		border-bottom-left-radius: .5rem;
		background-color: var(--#{$prefix}white);
		box-shadow: $box-shadow-lg;
		img {
		    border-bottom-right-radius: .5rem;
		    border-bottom-left-radius: .5rem;
		}
    }
    .browser-actions {
		position: absolute;
		top: 30px;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(var(--#{$prefix}black-rgb), .50);
		border-radius: 0 0 10px 10px;
		-webkit-backdrop-filter: saturate(180%) blur(20px);
		backdrop-filter: saturate(180%) blur(20px);
		opacity: 0;
		transition: ease all 0.35s;
    }
    &:hover {
    	.browser-actions {
    		opacity: 1;
    	}
    }
}