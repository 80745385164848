.h-theme-switch-01 {
    .dropdown-toggle {
        width: 40px;
        height: 40px;
        background-color: rgba(var(--#{$prefix}bg-mode), var(--#{$prefix}bg-opacity, 1));
        color: rgba(var(--#{$prefix}text-mode-re), var(--#{$prefix}text-opacity, 1));
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        padding: 0;
        font-size: 22px;
        border-radius: 50%;
        &:after {
            display: none;
        }
    }
}


.theme-switch-01 {
    width: 40px;
    border-radius: 50%;
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 23px;
    //color: var();
    .header-main.navbar-transparent:not(.headroom--not-top) & {
        color: var(--#{$prefix}white);
        background: transparent;
    }
    [data-bs-theme="dark"] & {
        color: var(--#{$prefix}white);
    }
    &:after {
        display: none;
    }
}