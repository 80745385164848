.spinner-01 {
    width: 50px;
    height: 50px;
    position: relative;

    span {
        width: 10px;
        height: 25px;
        animation: spinner-01 1s infinite ease-in-out;
        transform: translateZ(0);
        animation-delay: -0.16s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: currentColor;
            animation: spinner-01 1s infinite ease-in-out;
        }

        &:before {
            left: -15px;
            animation-delay: -0.32s;
        }

        &:after {
            left: 15px;
        }
    }
}

@keyframes spinner-01 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 35px;
    }

    40% {
        box-shadow: 0 -10px;
        height: 50px;
    }
}

.spinner-02 {
    font-size: 4px;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    transform: translateZ(0);
    border-radius: 50%;

    .s1-1,
    .s1-2 {
        position: absolute;
        border-radius: 50%;
    }

    .s1-1 {
        width: 5.2em;
        height: 10.2em;
        border-radius: 10.2em 0 0 10.2em;
        top: -0.1em;
        left: -0.1em;
        transform-origin: 5.1em 5.1em;
        animation: spinner-02 2s infinite ease 1.5s;
    }

    .s1-2 {
        width: 5.2em;
        height: 10.2em;
        border-radius: 0 10.2em 10.2em 0;
        top: -0.1em;
        left: 4.9em;
        transform-origin: 0.1em 5.1em;
        animation: spinner-02 2s infinite ease;
    }
}


@keyframes spinner-02 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.spinner-03 {
    font-size: 10px;
    width: 5em;
    height: 5em;
    margin: 1rem;
    position: relative;

    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-size: 10px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        animation: spinner-03 1.3s infinite linear;
        transform: translateZ(0);
    }
}


@keyframes spinner-03 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}


.spinner-04 {
	position: relative;
	font-size: 10px;
    width: 10em;
    height: 5em;
	span {
	    font-size: 10px;
	    width: 2.5em;
	    height: 2.5em;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	    transform: translateZ(0);
	    border-radius: 50%;
	    animation-fill-mode: both;
	    animation: spinner-04 1.8s infinite ease-in-out;
	    animation-delay: -0.16s;
	    &:before,
	    &:after {
	    	content: '';
		    position: absolute;
		    top: 0;
	    	border-radius: 50%;
		    width: 2.5em;
		    height: 2.5em;
		    animation-fill-mode: both;
		    animation: spinner-04 1.8s infinite ease-in-out;
	    }
	    &:before {
		    left: -3.5em;
		    animation-delay: -0.32s;
		}
		&:after {
		    left: 3.5em;
		}
	}
}


@keyframes spinner-04 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}