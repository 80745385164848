.section-heading {
	@include media-breakpoint-up(md) {
        margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 3.5rem;
    }
    margin-bottom: 2.5rem;
}

.title-01 {
    padding: 0 16px;
    display: inline-block;
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background: currentColor;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    &:after {
        left: auto;
        right: 0;
    }
}

.title-02 {
    padding: 12px 0 0;
    display: inline-block;
    position: relative;
    &:before {
        position: absolute;
        content: '';
        width: 15px;
        height: 2px;
        left: 0;
        top: 0;
        background: currentColor;
    }
}

.title-03 {
    position: relative;
    &::after {
        width: 50px;
        height: 2px;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var((--#{$prefix}title-03), var(--#{$prefix}primary));
        .text-center & {
            right: 0;
            margin: auto;
        }
        .text-end & {
            left: auto;
            right: 0;
        }
    }
}