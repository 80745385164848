// Header Fixed Effect
.header-main {
	transition: ease top 0.35s;
	&.headroom--unpinned {
		//top: -250px;
		box-shadow:$box-shadow;
	}
	&.headroom--pinned {
		top: 0;
	}
}

// Header Scroll Color
.header-main {
	&.headroom--not-top {
		background-color: var(--#{$prefix}body-bg);
	}
	&.headroom--pinned.headroom--not-top {
		box-shadow: $box-shadow-sm;
	}
	
}