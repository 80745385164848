.icon-sm {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.75rem;
    line-height: normal;
}

.icon {
    width: 2.2rem;
    height: 2.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.85rem;
    line-height: 1;
}

.icon-md {
    width: 2.8rem;
    height: 2.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    line-height: 1;
}

.icon-lg {
    width: 3.5rem;
    height: 3.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
}

.icon-xl {
    width: 5rem;
    height: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.9rem;
    line-height: 1;
}

.icon-xxl {
    width: 7rem;
    height: 7rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 3.5rem;
    line-height: 1;
}

.only-icon {
    display: inline-flex;
    i {
        font-size: 1.8rem;
        line-height: 1;
    }
    &-sm {
        i {
            font-size: 1rem;
        }
    }
    &-lg {
        i {
            font-size: 2.25rem;
        }
    }
    &-xl {
        i {
            font-size: 3rem;
        }
    }
}