.border-style {
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 80%;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        height: 1px;
        background: radial-gradient(ellipse at center, var(--#{$prefix}gray-300) 0, rgba(var(--#{$prefix}white-rgb), 0) 75%);
    }

    &.bottom {
        &:after {
            bottom: 0;
        }
    }

    &.top {
        &:after {
            top: 0;
        }
    }

    &.light {
        &:after {
            background: radial-gradient(ellipse at center, rgba(var(--#{$prefix}white-rgb), 0.2) 0, rgba(var(--#{$prefix}white-rgb), 0) 75%);
        }
    }
}

.separated {
    overflow: hidden;

    >* {
        box-shadow: -1px -1px 0px 0px var(--#{$prefix}gray-300);
    }
}